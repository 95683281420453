<template>
  <div class="home">
    <div class="navigation">
      <span @click="goMedia">融媒体</span>
      <i class="el-icon-arrow-right"></i>
      <span class="nav_info">{{ dataForm.title }}</span>
    </div>
    <div class="content">
      <section
        class="video"
        :style="open || !isShowMore ? 'height:3.65rem' : ''"
      >
        <div class="video_title">
          {{ dataForm.title }}
        </div>
        <div class="video_publish">
          <!-- <VideoSelef autoplay :src="dataForm.fileUrl" :poster="dataForm.picUrl" /> -->

          <video
            webkit-playsinline="true"
            playsinline="true"
            x5-playsinline="true"
            x-webkit-airplay="allow"
            x5-video-player-fullscreen="true"
            x5-video-player-type="h5"
            @contextmenu="handleMouse"
            :poster="dataForm.mp3pic"
            :src="dataForm.fileUrl"
            controlslist="nodownload"
            controls="controls"
          ></video>
        </div>
        <div class="video_introduction" :class="{ close: open }">
          <!-- {{ dataForm.content }} -->
          <div ref="content" v-html="dataForm.content"></div>
          <span v-if="isShowMore" @click="handleOpen"
            >{{ open ? "展开" : "收起" }}
          </span>
        </div>
      </section>
      <section class="relative" v-show="booksList.length != 0">
        <div class="relative_title">相关推荐</div>
        <ul class="magazine">
          <li
            v-for="(item, index) in booksList"
            v-show="index < page * 6 && index >= (page - 1) * 6"
            :key="item.id"
            @click="goDetail(item)"
          >
            <figure>
              <img :src="item.coverUrl" alt="" />
            </figure>
            <div class="magazine-msg">
              <span>{{ item.bookName }}</span>
            </div>
          </li>
        </ul>
        <div class="buttons" v-if="booksList.length >= 6">
          <div
            class="left"
            :class="booksList.length > 6 && page > 1 ? 'active' : ''"
          >
            <i class="el-icon-back" @click="goleft"></i>
          </div>
          <div
            class="right"
            :class="
              booksList.length > 6 && page * 6 < booksList.length
                ? 'active'
                : ''
            "
          >
            <i class="el-icon-right" @click="goright"></i>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import music from "../../assets/home/music.png";
// import VideoSelef from './video.vue'
export default {
  name: "book",
  data() {
    return {
      open: true, // 内容状态是否打开
      dataForm: {},
      booksList: [],
      music: music,
      page: 1,
      isShowMore: false, // 控制展开更多的显示与隐藏

      textHeight: null, // 框中内容的高度
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    handleOpen() {
      this.open = !this.open;
    },
    handleMouse(e) {
      e.preventDefault();
    },
    goleft() {
      if (this.booksList.length > 6 && this.page > 1) {
        this.page -= 1;
      }
    },
    getHeight() {
      this.$nextTick(() => {
        // 这里具体行数可依据需求自定义
        let lineHeight = 0.25 * 2;
        this.textHeight = `${lineHeight}rem`;
        let fontSize = getComputedStyle(window.document.documentElement)[
          "font-size"
        ];

        //以上方法返回的font-size会带单位px，如果不想要px可以做一下处理
        // +fontSize.slice(0, fontSize.indexOf('px'))

        console.log(
          this.$refs.content.offsetHeight,
          "this.$refs.content.offsetHeight",
          +fontSize.slice(0, fontSize.indexOf("px")),

          fontSize,
          Number(lineHeight),
          Number(lineHeight) * +fontSize.slice(0, fontSize.indexOf("px"))
        );
        if (
          this.$refs.content.offsetHeight >
          Number(lineHeight) * +fontSize.slice(0, fontSize.indexOf("px"))
        ) {
          this.isShowMore = true;
          this.open = true;
        } else {
          this.isShowMore = false;
          this.open = false;
        }
      });
    },
    relativeBooks(id) {
      // this.$router.push(
      //   {  path: "/recommend",
      //   query: { publishRange: msg }}

      // )
      let url = this.$router.resolve({
        path: "/recommend",
        query: { publishRange: id },
      });
      window.open(url, "_blank");
    },
    goright() {
      if (this.booksList.length > 6 && this.page * 6 < this.booksList.length) {
        this.page += 1;
      }
    },
    goMedia() {
      this.$router.push({
        path: "/convergencemedia",
        query: {},
      });
    },
    goDetail(item) {
      let url = this.$router.resolve({
        path: "/recommend/bookdetail",
        query: { id: item.id, publishRange: item.publishRange },
      });
      console.log(url, "url");
      window.open(url.href, "_blank");
      // this.$router.push({
      //   path: "/recommend/bookdetail",
      //   query: {
      //     id: id,
      //   },
      // });
    },
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      // var test = base64 && base64.replaceAll(/<[^>]+>/g, "");
      return base64;
    },
    picUrlcheck(Url) {
      let URL = JSON.parse(Url)[0];
      let urlcheck = URL.response.substring(URL.response.lastIndexOf(".") + 1);
      console.log(urlcheck, "URL", URL);
      if (urlcheck == "mp3") {
        console.log("mp3");
        return this.music;
      }
    },

    getInfo() {
      this.$http
        .get(`/convergencemedia/getConvergenceMediaInfoById`, {
          params: { id: this.$route.query.id },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataForm = {
            ...res.data,
            content: this.decode(res.data.content),
            mp3pic:
              res.data.picUrl || this.picUrlcheck(res.data.fileUrl, res.data),
            // catalogue:this.decode(res.data.catalogue),
          };
          this.dataForm.fileUrl = JSON.parse(this.dataForm.fileUrl)[0].response;
          // console.log(JSON.parse(this.dataForm.fileUrl)[0].response,'JSON.parse(dataForm.fileUrl).raw.response');
          this.getrelative(res.data.relatedRecommendation);
          console.log(this.dataForm, "dataform");
          this.getHeight();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    getrelative(ids) {
      this.$http
        .get(`/convergencemedia/getBooksInfoByIds`, {
          params: { ids: ids, limit: 4, page: 1 },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          // this.dataForm = {

          //   ...res.data,
          //   content:this.decode(res.data.content),
          //   // catalogue:this.decode(res.data.catalogue),
          // }
          this.booksList = res.data.list;
          console.log(res.data, "resdata");
        })
        .catch(() => {});
    },
  },
  components: {
    // VideoSelef
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding: 0.32rem 0.64rem;
  padding-top: 1.16rem;
  position: relative;
  .navigation {
    position: absolute;
    left: 0.64rem;
    top: 0.7rem;
    span {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.2rem;
      cursor: pointer;
    }
    .nav_info {
      font-size: 0.14rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.2rem;
    }
    i {
      color: rgba(178, 1, 3, 1);
      font-size: 0.16rem;
      margin: 0.05rem;
      font-weight: 600;
    }
  }

  .content {
    box-sizing: border-box;
    padding: 0.32rem 0.35rem;
    width: 8.68rem;
    background: #ffffff;
    width: 13.12rem;
    min-height: 7.56rem;
    background: #ffffff;
    border-radius: 0.02rem 0.02rem 0rem 0.02rem;
    display: flex;
    justify-content: space-between;
    .video {
      width: 7rem;
    }
    .video_title {
      font-size: 0.2rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.28rem;
      margin-bottom: 0.32rem;
    }
    .video_publish {
      width: 7rem;
      height: 4.5rem;
      background: rgba(0, 0, 0, 0.85);

      margin-bottom: 0.32rem;
      video {
        width: 100%;
        height: 100%;
      }
    }
    .video_introduction {
      font-size: 0.14rem;
      position: relative;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 0.22rem;
      width: 7rem;
      // height: .44rem;
      //盒子中内容竖直排列

      span {
        position: absolute;
        right: 0rem;
        bottom: 0;
        background: #ffffff;
        font-size: 0.14rem;
        cursor: pointer;
        font-weight: 400;
        color: #c3282b;
        line-height: 0.2rem;
      }
    }
    .close {
      overflow: hidden; //溢出内容隐藏
      max-height: 0.44rem;
    }
  }
  .relative {
    width: 5.12rem;
    position: relative;
    .buttons {
      position: absolute;
      right: 0;
      display: flex;
      i {
        cursor: pointer;
      }

      div {
        margin-left: 0.16rem;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 0.02rem;
        border: 0.01rem solid rgba(0, 0, 0, 0.25);
        color: rgba(0, 0, 0, 0.25);
        line-height: 0.4rem;
        text-align: center;
        font-size: 0.24rem;
        &.active {
          border: 0.01rem solid var(--backgroundColor);
          color: var(--backgroundColor);
        }
      }
    }
  }
  .relative_title {
    font-size: 0.2rem;
    margin-bottom: 0.31rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.28rem;
  }
  .magazine {
    width: 5.12rem;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-bottom: 1.04rem;
    height: 4.24rem;

    li {
      // width: 1.42rem;

      margin-right: 0.1rem;
      figure {
        width: 1.6rem;
        height: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f5f5f5;
        img {
          height: 90%;
        }
      }

      .magazine-msg {
        margin-top: 0.15rem;
        margin-bottom: 0.23rem;
        font-size: 0.16rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.24rem;
        text-align: center;
        width: 1.6rem;
        span {
          display: block;
          width: 1.6rem;
          overflow: hidden; // 溢出隐藏
          white-space: nowrap; // 强制一行
          text-overflow: ellipsis; // 文字溢出显示省略号
        }
      }
    }
    li:nth-child(3n) {
      margin-right: 0;
    }
  }
}
@media (max-width: 768px) {
  //  .magazine-msg{

  //   }

  .home {
    margin: 0;
    flex-wrap: wrap;
    padding: 0;
    .relative .magazine li .magazine-msg {
      span {
        width: auto;
      }
    }
    .navigation {
      display: none;
    }
    figure {
      height: 4.2rem;
      width: 100vw;
    }
    .content {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: auto;
      height: auto;
      min-height: 0;
      .video {
        display: flex;
        flex-wrap: wrap;
        // min-height: 3.65rem;
      }
      .video_title {
        order: 2;
        margin: 0 0.16rem;
        margin-bottom: 0.16rem;
        font-size: 0.16rem;
        width: 100vw;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.24rem;
      }

      .video_publish {
        order: 1;
        width: 100vw;

        height: 2.41rem;
        margin-bottom: 0.16rem;
      }
      .video_introduction {
        order: 3;
        margin: 0 0.16rem;
        margin-bottom: 0.24rem;
        width: 3.4rem;
      }
    }
    .relative {
      padding: 0.16rem;
      padding-top: 0.26rem;

      .relative_title {
        font-size: 0.16rem;

        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.24rem;
        margin-bottom: 0.15rem;
      }
      .magazine {
        width: auto;
        li {
          width: auto;
          figure {
            width: 1.02rem;
            height: 1.41rem;
            margin-bottom: 0.11rem;
          }
          .magazine-msg {
            width: 1.02rem;
            font-size: 0.14rem;

            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 0.22rem;
            margin-top: 0.11rem;
          }
        }
      }
      .buttons {
        // display: none;
        top: 0.3rem;
        right: 0.3rem;
        .left {
          width: 0.2rem;
          height: 0.2rem;
          line-height: 0.15rem;
          i {
            font-size: 0.15rem;
          }
        }
        .right {
          width: 0.2rem;
          height: 0.2rem;
          line-height: 0.15rem;
          i {
            font-size: 0.15rem;
          }
        }
      }
    }
    .relative::before {
      content: "";
      display: block;
      height: 0.1rem;
      width: 100vw;
      position: absolute;
      top: 0;
      left: 0rem;
      background: #f6f6f6;
    }
  }
}
</style>
